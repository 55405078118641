<template>
	<div>
		<div class="contact-mobile" v-if="isMobile">
			<div class="banner posRelative">
				<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
				<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
				<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
					:muted="isMuted" v-if="banner.type===2"></video>
				<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
					<span>{{banner.title}}</span>
					<span class="margin-top40">{{banner.sub_title}}</span>
				</div>
				<div v-if="banner.type===2">
					<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
						@click.stop="isMuted=false">
					<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else
						@click.stop="isMuted=true">
				</div>
			</div>
			<div class="main-content">
				<div class="title-text" v-if="language==='0'">联系我们</div>
				<div class="title-text" v-if="language==='1'">Contact Us</div>
				<div class="title-line"></div>
				<div class="column-me margin-top30">
					<div class="flex1">
						<div class="row-me row-center">
							<div class="point-div"></div>
							<div class="title1 flex1 margin-left20">{{language==='0'?'电话':'Phone'}}：{{detailItem.phone}}
							</div>
						</div>
						<div class="row-me row-center margin-top30">
							<div class="point-div"></div>
							<div class="title1 flex1 margin-left20">
								{{language==='0'?'地址':'Address'}}：{{detailItem.address}}
							</div>
						</div>
						<div class="row-me row-center margin-top30">
							<div class="point-div"></div>
							<div class="title1 flex1 margin-left20">
								{{language==='0'?'邮箱':'Email'}}：{{detailItem.recruit_email}}
							</div>
						</div>
					</div>
					<div class="column-me row-center margin-top30">
						<img :src="detailItem.qrcode" class="img-code">
						<div class="code-text margin-top15" v-if="language==='0'">扫一扫，关注公众号</div>
						<div class="code-text margin-top15" v-if="language==='1'">
							Sweep and pay attention to official account
						</div>
					</div>
				</div>
				<div id="container" class="map-div"></div>
			</div>
		</div>


		<div class="contact" v-else>
			<div class="banner posRelative">
				<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
				<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
				<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
					:muted="isMuted" v-if="banner.type===2"></video>
				<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
					<span>{{banner.title}}</span>
					<span class="margin-top40">{{banner.sub_title}}</span>
				</div>
				<div v-if="banner.type===2">
					<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
						@click.stop="isMuted=false">
					<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else
						@click.stop="isMuted=true">
				</div>
			</div>
			<div class="main-content">
				<div class="title-text" v-if="language==='0'">联系我们</div>
				<div class="title-text" v-if="language==='1'">Contact Us</div>
				<div class="title-line"></div>
				<div class="row-me row-center margin-top30">
					<div class="flex1">
						<div class="row-me row-center">
							<div class="point-div"></div>
							<div class="title1 flex1 margin-left30">{{language==='0'?'电话':'Phone'}}：{{detailItem.phone}}
							</div>
						</div>
						<div class="row-me row-center margin-top60">
							<div class="point-div"></div>
							<div class="title1 flex1 margin-left30">
								{{language==='0'?'地址':'Address'}}：{{detailItem.address}}
							</div>
						</div>
						<div class="row-me row-center margin-top60">
							<div class="point-div"></div>
							<div class="title1 flex1 margin-left30">
								{{language==='0'?'邮箱':'Email'}}：{{detailItem.recruit_email}}
							</div>
						</div>
					</div>
					<div class="column-me row-center margin-left30">
						<img :src="detailItem.qrcode" class="img-code anim-bigger-small">
						<div class="code-text margin-top15" v-if="language==='0'">扫一扫，关注公众号</div>
						<div class="code-text margin-top15" v-if="language==='1'">
							Sweep and pay attention to official account
						</div>
					</div>
				</div>
				<div id="container" class="map-div"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				banner: {},
				showBannerAnim: false,
				lat: 0,
				lng: 0,
				detailItem: {},
				language: '', //0中文，1英文
				isMuted: true, //视频是否静音
				isMobile: '', //0电脑，1手机
			}
		},
		watch: {
			navItem: {
				handler: function(newVal, oldVal) {
					this.getBanner()
					this.getData()
				}
			}
		},
		computed: {
			navItem() {
				return this.$store.state.navItem;
			},
		},
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.getBanner()
			this.getData()
		},
		activated() {
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			this.showBannerAnim = false
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
		},
		mounted() {

		},
		methods: {
			initMap() {
				var map = new AMap.Map('container', {
					resizeEnable: true,
					center: [this.lng, this.lat],
					zoom: 17,
					viewMode: '3D' //使用3D视图
				});
				var marker = new AMap.Marker({
					position: [this.lng, this.lat], //位置
					title: '健壹资本',
					icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
					offset: new AMap.Pixel(-30, -40)
				})
				map.add(marker); //添加到地图
			},
			getBanner() {
				this.banner = {}
				let link_url = this.$store.state.navItem.link_url
				if (link_url == '/contact') {
					this.$postRequest('/web/navInfo', {
						id: this.$store.state.navItem.id
					}).then((res) => {
						if (res) {
							this.banner = res.data
						}
					}).catch((error) => {
						console.log(error)
					})
				}
			},
			//获取数据
			getData() {
				this.$postRequest('/web/sysconfig', {}).then((res) => {
					if (res) {
						this.detailItem = res.data
						this.lat = Number(res.data.lat)
						this.lng = Number(res.data.lng)
						this.initMap()
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.contact-mobile {
		.banner {
			width: 100%;
			height: 35vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 25px;
				color: #FFFFFF;
				position: absolute;
				left: 20px;
				top: 30%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 20px;

			.point-div {
				width: 12px;
				height: 12px;
				background: #999999;
				border-radius: 50%;
			}

			.title1 {
				font-size: 20px;
				color: #0B0F0F;
			}

			.img-code {
				width: 200px;
				height: 200px;
				object-fit: cover;
			}

			.code-text {
				font-size: 18px;
				color: #0B0F0F;
				text-align: center;
			}

			.map-div {
				width: 100%;
				height: 30vh;
				margin-top: 50px;
			}
		}

	}

	.contact {
		.banner {
			width: 100%;
			height: 80vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 80px;
				color: #FFFFFF;
				position: absolute;
				left: 278px;
				top: 40%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 100px 278px;

			.point-div {
				width: 12px;
				height: 12px;
				background: #999999;
				border-radius: 50%;
			}

			.title1 {
				font-size: 18px;
				color: #0B0F0F;
			}

			.img-code {
				width: 200px;
				height: 200px;
				object-fit: scale-down;
			}

			.code-text {
				font-size: 18px;
				color: #0B0F0F;
			}

			.map-div {
				width: 100%;
				height: 506px;
				margin-top: 140px;
			}
		}

	}
</style>
